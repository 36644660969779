import React, { useEffect, useMemo } from "react";

import { MetricsIntervalContext } from "@tether-web-portal/context-providers";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";

export const useMetricsInterval = (initialDataInterval?: MetricDataInterval) => {
  const context = React.useContext(MetricsIntervalContext);

  const initialDataIntervalToUse = useMemo(() => initialDataInterval, [initialDataInterval]);

  useEffect(() => {
    if (initialDataIntervalToUse) {
      context.setMetricsInterval(initialDataIntervalToUse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataIntervalToUse]);

  return context;
};
