import { Button, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import useCreatePropertyReport from "../../lib/api/properties/hooks";
import { useHasPermission } from "../../lib/hooks/useHasPermission";
import { ReportType } from "../../lib/reports/ReportService";
import { Box, Checkbox, Heading } from "@chakra-ui/react";
import { DefaultModal } from "../modals/DefaultModal";
import DateRangeControls from "../property/DateRangeControls";
import { PropertyTagList } from "../property/PropertyTagList";
import { endOfMonth, format, getDaysInMonth, startOfMonth, subMonths } from "date-fns";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";
import { useMetricsInterval } from "@tether-web-portal/hooks";

interface GenerateManagementReportButtonProps {
  reportType: ReportType;
  buttonTitle: string;
  showPropertyTags: boolean;
  showHideAddressesOption: boolean;
  customReportConfirm?: (
    startDate: string,
    endDate: string,
    tags?: string[],
    hideAddresses?: boolean
  ) => void;
  entityId?: string;
}

export const GenerateReportButton = ({
  reportType,
  showHideAddressesOption,
  showPropertyTags,
  entityId,
  buttonTitle,
  customReportConfirm,
}: GenerateManagementReportButtonProps) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { metricsTimeStamps, setCustomMetricsInterval } = useMetricsInterval();
  const lastMonth = useMemo(() => subMonths(new Date(), 1), []);

  const hasReportPermission = useHasPermission("REPORTS");
  const { loadingReportText, createPropertyReport } = useCreatePropertyReport();

  const [tags, setTags] = useState<string[]>([]);
  const [hideAddresses, setHideAddresses] = useState(false);

  const handleReport = async (
    startDate: string,
    endDate: string,
    tags?: string[],
    hideAddresses?: boolean
  ) => {
    if (!entityId) {
      return;
    }

    if (customReportConfirm) {
      return customReportConfirm(startDate, endDate, tags, hideAddresses);
    }
    const report = await createPropertyReport(entityId, reportType, {
      dateRange: {
        startDate,
        endDate,
      },
      propertyTags: tags,
      hideAddresses,
    });

    if (report.status === "COMPLETED") {
      toast({
        title: "Report Generated",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      window.location.href = report.reportLocation;
      onClose();
    } else {
      console.log("ERROR GENERATING REPORT", report);
      toast({
        title: "Could not generate report",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    setCustomMetricsInterval(startOfMonth(lastMonth).toISOString(), endOfMonth(lastMonth).toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMonth]);

  return (
    <>
      <Tooltip
        label={
          loadingReportText === undefined && !hasReportPermission
            ? "You do not have permission"
            : loadingReportText
        }
        hasArrow
      >
        {/* This span only is here to make the tooltip work */}
        <span>
          <Button
            colorScheme={"blue"}
            disabled={!hasReportPermission}
            onClick={onOpen}
            pointerEvents="all"
            variant={"outline"}
            bg="white"
          >
            {buttonTitle}
          </Button>
        </span>
      </Tooltip>
      {isOpen && (
        <DefaultModal
          isOpen={isOpen}
          onClose={onClose}
          title={buttonTitle}
          confirmText="Generate"
          onConfirm={() =>
            handleReport(metricsTimeStamps.fromTimestamp, metricsTimeStamps.toTimestamp, tags, hideAddresses)
          }
          minWidth={650}
          overflowY="visible"
        >
          <Heading size="sm" pb={2}>
            Report Dates
          </Heading>
          <Box display="inline-block">
            <DateRangeControls
              maxSelectedDays={-1}
              buttonOptions={[
                {
                  hours: (getDaysInMonth(subMonths(new Date(), 3)) - 1) * 24,
                  endDate: endOfMonth(subMonths(new Date(), 3)).toISOString(),
                  label: format(subMonths(new Date(), 3), "MMMM yyyy"),
                  interval: MetricDataInterval.CUSTOM,
                },
                {
                  hours: (getDaysInMonth(subMonths(new Date(), 2)) - 1) * 24,
                  endDate: endOfMonth(subMonths(new Date(), 2)).toISOString(),
                  label: format(subMonths(new Date(), 2), "MMMM yyyy"),
                  interval: MetricDataInterval.CUSTOM,
                },
                {
                  hours: (getDaysInMonth(lastMonth) - 1) * 24,
                  endDate: endOfMonth(lastMonth).toISOString(),
                  label: format(lastMonth, "MMMM yyyy"),
                  interval: MetricDataInterval.CUSTOM,
                },
              ]}
            />
          </Box>
          {showPropertyTags && (
            <Box pt={4}>
              <Heading size="sm" pb={2}>
                Filter Properties by Tag:
              </Heading>
              <PropertyTagList
                rightButton={false}
                tags={tags}
                onTagsUpdated={(newTags) => setTags(newTags)}
                editable
              />
            </Box>
          )}

          {Boolean(showHideAddressesOption) && (
            <>
              <Heading size="sm" pb={2}>
                Other Options
              </Heading>
              <Checkbox
                isChecked={hideAddresses}
                onChange={(event) => setHideAddresses(event.target.checked)}
              >
                Hide Property Addresses (Custom IDs will be shown instead)
              </Checkbox>
            </>
          )}
        </DefaultModal>
      )}
    </>
  );
};
