import React from "react";
import { HStack } from "@chakra-ui/react";
import { BlackToggleButton } from "./BlackToggleButton";
import { MetricDataInterval, MetricDataIntervalTitleMap } from "@tether-web-portal/types/metricsTypes";

export type TimeButtonOptions = {
  hours?: number;
  endDate?: string;
  interval: MetricDataInterval;
  label?: string;
};

export const defaultTimeButtons: TimeButtonOptions[] = [
  // { hours: 12, label: "12 hours" },
  { interval: MetricDataInterval.HOURS_24 },
  { interval: MetricDataInterval.DAYS_7 },
  { interval: MetricDataInterval.DAYS_30 },
  { interval: MetricDataInterval.MONTHS_3 },
];

interface PrimaryButtonProps {
  onSelect: (option: TimeButtonOptions) => void;
  selectedValue: TimeButtonOptions | null;
  overridden: boolean /* Set to true if another component has set the date range which overrides these buttons */;
  buttons?: TimeButtonOptions[];
}

export const TimeRangeButtons = ({ buttons, onSelect, selectedValue, overridden }: PrimaryButtonProps) => {
  return (
    <HStack bg="white">
      {(buttons || defaultTimeButtons).map((button) => {
        const isSelected = selectedValue === button && !overridden;

        return (
          <BlackToggleButton
            key={button.label ?? button.interval}
            title={button.label ?? MetricDataIntervalTitleMap[button.interval]}
            isSelected={isSelected}
            onClick={() => onSelect(button)}
          />
        );
      })}
    </HStack>
  );
};
