import { HStack, Stack } from "@chakra-ui/react";
import { useMetricsInterval } from "@tether-web-portal/hooks";
import { MetricDataInterval } from "@tether-web-portal/types/metricsTypes";
import { TimestampRange } from "@tether-web-portal/types/TimestampRange";
import { differenceInDays, endOfDay, format, startOfDay, subHours } from "date-fns";
import { useMemo, useState } from "react";
import {
  defaultTimeButtons,
  TimeButtonOptions,
  TimeRangeButtons,
} from "../../components/button/TimeRangeButtons";
import { DateRangePicker } from "../../components/datepicker/DateRangePicker";
import { ReadingResolution } from "../../lib/api/readings/hooks";

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface DateRangeControlsProps {
  buttonOptions?: TimeButtonOptions[];
  maxSelectedDays?: number;
}

export interface DateRangeResolution {
  powerResolution: ReadingResolution;
  environmentalResolution: ReadingResolution;
}

export const getResolutionDaysForRange = (dateRange: TimestampRange): DateRangeResolution => {
  if (!dateRange.toTimestamp || !dateRange.fromTimestamp) {
    return {
      powerResolution: "HOURLY",
      environmentalResolution: "HOURLY",
    };
  }

  const days = differenceInDays(new Date(dateRange.toTimestamp), new Date(dateRange.fromTimestamp));

  let powerResolution: ReadingResolution = "HOURLY";

  // power

  if (days > 25) {
    powerResolution = "DAILY";
  } else if (days >= 2) {
    powerResolution = "HOURLY";
  } else {
    powerResolution = "FIFTEENMINUTE";
  }

  // environmental
  const environmentalResolution: ReadingResolution = days > 4 ? "HOURLY" : "FIFTEENMINUTE";
  return {
    environmentalResolution,
    powerResolution,
  };
};

const getSelectedButtonFromDateRange = (
  timeStamps: TimestampRange,
  selectedInterval: MetricDataInterval,
  buttonOptions: TimeButtonOptions[]
) => {
  // end date is start date check if is one of the known ranges
  const match = buttonOptions.find((button) => {
    // Check end dates match
    if (
      button.endDate &&
      format(new Date(button.endDate), "yyyy-MM-dd") !==
        format(new Date(timeStamps.toTimestamp), "yyyy-MM-dd")
    ) {
      return false;
    }

    if (!!button.hours) {
      // if we are checking within a day, check the hours match also
      const dateFormat = button.hours > 24 ? "yyyy-MM-dd" : "yyyy-MM-dd HH";

      // Check start dates match
      const safeEndDate = button.endDate ? new Date(button.endDate) : new Date();
      const startDate = subHours(safeEndDate, button.hours);
      const formattedStartDate = format(startDate, dateFormat);
      const formattedCurrentFromDate = format(new Date(timeStamps.fromTimestamp), dateFormat);
      if (formattedStartDate !== formattedCurrentFromDate) {
        return false;
      }

      return true;
    }

    if (button.interval !== MetricDataInterval.CUSTOM && button.interval === selectedInterval) {
      return true;
    }

    return false;
  });

  return match || null;
};

export const maxReadingDays = 366;

export const DateRangeControls = ({
  buttonOptions = defaultTimeButtons,
  maxSelectedDays: maxSelectedDaysProp = maxReadingDays,
}: DateRangeControlsProps) => {
  const {
    metricsTimeStamps,
    metricsCurrentDataInterval,
    setMetricsInterval,
    setCustomMetricsInterval,
  } = useMetricsInterval();

  const selectedButton = useMemo(
    () => getSelectedButtonFromDateRange(metricsTimeStamps, metricsCurrentDataInterval, buttonOptions),
    [buttonOptions, metricsTimeStamps, metricsCurrentDataInterval]
  );

  const maxSelectedDays = useMemo(() => (maxSelectedDaysProp === -1 ? undefined : maxSelectedDaysProp), [
    maxSelectedDaysProp,
  ]);

  const [customDateRange, setCustomDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const setNewTimeRangeFromButton = (button: TimeButtonOptions) => {
    if (button.interval !== MetricDataInterval.CUSTOM) {
      setMetricsInterval(button.interval);
    } else if (button.hours && button.endDate) {
      const endDate = button.hours < 25 ? new Date(button.endDate) : endOfDay(new Date(button.endDate));
      const startDate = subHours(endDate, button.hours);
      setCustomMetricsInterval(startDate.toISOString(), endDate.toISOString());
    }
  };

  return (
    <HStack justifyContent={"flex-end"}>
      <HStack>
        <Stack bg={"white"} p={1} border="1px" borderColor="gray.200" borderRadius="md">
          <TimeRangeButtons
            onSelect={setNewTimeRangeFromButton}
            overridden={selectedButton === null}
            selectedValue={selectedButton}
            buttons={buttonOptions}
          />
        </Stack>
        <DateRangePicker
          startDate={customDateRange.startDate ? customDateRange.startDate : null}
          endDate={customDateRange.endDate ? customDateRange.endDate : null}
          onDatesChange={(data) => {
            setCustomDateRange({
              startDate: data.startDate,
              endDate: data.endDate && endOfDay(data.endDate),
            });
            if (data.startDate && data.endDate) {
              setCustomMetricsInterval(
                startOfDay(data.startDate).toISOString(),
                endOfDay(data.endDate).toISOString()
              );
            }
          }}
          bg={selectedButton === null ? "black" : "white"}
          color={selectedButton === null ? "white" : undefined}
          borderColor="gray.100"
          borderRadius={6}
          maxBookingDate={new Date()}
          horizontalOffset={-460}
          maxSelectedDays={maxSelectedDays}
          showDateRangeOnButton={selectedButton === null}
          onClick={() => {
            // If the custom date button is clicked and a range has already been specified there, select it
            if (customDateRange.startDate && customDateRange.endDate) {
              setCustomMetricsInterval(
                startOfDay(customDateRange.startDate).toISOString(),
                endOfDay(customDateRange.endDate).toISOString()
              );
            }
          }}
        />
      </HStack>
    </HStack>
  );
};

export default DateRangeControls;
